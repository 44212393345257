// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { formatDate, formatDistanceTime, getValue } from '../../contexts/Utils';
import { PenaltiesList } from '../../contexts/Contexts';
import styled from 'styled-components';
import TitleSeparator from '../BaseComponents/TitleSeparator';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MissionDetailRecap = (props) => {

  let mission = props.mission
  let data, parentAddress;


  const origin_address = `${mission.origin_address}, ${mission.origin_cp} ${mission.origin_city}`;
  const destination_address = `${mission.destination_address}, ${mission.destination_cp} ${mission.destination_city}`;

  if (props.recovery) {
    data = props.data
    parentAddress = `${data.origin_address}, ${data.origin_cp} ${data.origin_city}`;
  }

  let penalties = 0;

  PenaltiesList.map((penalty) => {
    if (mission[penalty.name]) {
      penalties += mission[penalty.name];
    }
  })

  let vehicleIcon = <S.Vehicle xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 640 512"><path d="M640 320V368C640 385.7 625.7 400 608 400H574.7C567.1 445.4 527.6 480 480 480C432.4 480 392.9 445.4 385.3 400H254.7C247.1 445.4 207.6 480 160 480C112.4 480 72.94 445.4 65.33 400H32C14.33 400 0 385.7 0 368V256C0 224.5 22.78 198.3 52.78 192.1L97.05 82.29C109.2 51.92 138.6 32 171.3 32H353.2C377.5 32 400.5 43.05 415.7 62.02L519.9 192.2C586.9 196.3 640 251.1 640 320H640zM171.3 64C151.7 64 134.1 75.95 126.8 94.17L87.63 192H224V64H171.3zM256 192H478.7L390.7 82.01C381.6 70.63 367.8 64 353.2 64H256L256 192zM541.1 400C543.3 394.9 544 389.5 544 384C544 378.5 543.3 373.1 541.1 368C534.9 340.4 509.8 320 480 320C450.2 320 425.1 340.4 418 368C416.7 373.1 416 378.5 416 384C416 389.5 416.7 394.9 418 400C425.1 427.6 450.2 448 480 448C509.8 448 534.9 427.6 541.1 400zM385.3 368C392.9 322.6 432.4 288 480 288C527.6 288 567.1 322.6 574.7 368H608V320C608 266.1 565 224 512 224H64C46.33 224 32 238.3 32 256V368H65.33C72.94 322.6 112.4 288 160 288C207.6 288 247.1 322.6 254.7 368H385.3zM221.1 400C223.3 394.9 224 389.5 224 384C224 378.5 223.3 373.1 221.1 368C214.9 340.4 189.8 320 160 320C130.2 320 105.1 340.4 98.02 368C96.7 373.1 96 378.5 96 384C96 389.5 96.7 394.9 98.02 400C105.1 427.6 130.2 448 160 448C189.8 448 214.9 427.6 221.1 400z" /></S.Vehicle>

  if (mission.vehicle_type === 2) {
    vehicleIcon = <S.Vehicle xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 640 512"><path d="M64 416C28.65 416 0 387.3 0 352V64C0 28.65 28.65 0 64 0H352C387.3 0 416 28.65 416 64V96H481.3C495.3 96 508.6 102.1 517.7 112.8L596.4 204.6C603.9 213.3 608 224.4 608 235.8V384H624C632.8 384 640 391.2 640 400C640 408.8 632.8 416 624 416H576C576 469 533 512 480 512C426.1 512 384 469 384 416H255.1C255.1 469 213 512 159.1 512C106.1 512 63.1 469 63.1 416H64zM32 64V352C32 369.7 46.33 384 64 384H69.46C82.64 346.7 118.2 320 160 320C201.8 320 237.4 346.7 250.5 384H384V64C384 46.33 369.7 32 352 32H64C46.33 32 32 46.33 32 64zM570.9 224L493.4 133.6C490.4 130 485.1 128 481.3 128H416V224H570.9zM416 256V344.4C432.1 329.2 455.4 320 480 320C521.8 320 557.4 346.7 570.5 384H576V256H416zM160 352C124.7 352 96 380.7 96 416C96 451.3 124.7 480 160 480C195.3 480 224 451.3 224 416C224 380.7 195.3 352 160 352zM480 480C515.3 480 544 451.3 544 416C544 380.7 515.3 352 480 352C444.7 352 416 380.7 416 416C416 451.3 444.7 480 480 480z" /></S.Vehicle>
  } else if (mission.vehicle_type === 3) {
    vehicleIcon = <S.Vehicle xmlns="http://www.w3.org/2000/svg" width='24' height='24' viewBox="0 0 640 512"><path d="M640 250.7V360C640 379.4 626.3 395.5 607.1 399.2L608 400C608 444.2 572.2 480 528 480C483.8 480 448 444.2 448 400H288C288 444.2 252.2 480 208 480C181.8 480 158.6 467.4 144 448C129.4 467.4 106.2 480 80 480C35.82 480 0 444.2 0 400V104C0 64.24 32.24 32 72 32H408C447.8 32 480 64.24 480 104V128H536C551.1 128 565.3 135.1 574.4 147.2L630.4 221.9C636.6 230.2 640 240.3 640 250.7zM32 104V335.1C45.37 325.1 61.99 320 80 320C106.2 320 129.4 332.6 144 351.1C158.6 332.6 181.8 320 208 320C240.8 320 268.1 339.7 281.3 368H448V104C448 81.91 430.1 64 408 64H72C49.91 64 32 81.91 32 104zM528 320C560.8 320 588.9 339.7 601.3 367.9C605.1 367.3 608 363.1 608 360V256H480V335.1C493.4 325.1 509.1 320 528 320zM480 160V224H592L548.8 166.4C545.8 162.4 541 160 536 160H480zM528 448C554.5 448 576 426.5 576 400C576 373.5 554.5 352 528 352C501.5 352 480 373.5 480 400C480 426.5 501.5 448 528 448zM208 352C181.5 352 160 373.5 160 400C160 426.5 181.5 448 208 448C234.5 448 256 426.5 256 400C256 373.5 234.5 352 208 352zM80 448C106.5 448 128 426.5 128 400C128 373.5 106.5 352 80 352C53.49 352 32 373.5 32 400C32 426.5 53.49 448 80 448z" /></S.Vehicle>
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    {Object.keys(mission).length && <S.Wrapper>
      <S.Line>
        <S.Container>
          <S.FlexBetween>
            <S.Reference>N°{mission.reference}</S.Reference>
            {mission.weproov_code && <S.Weproov>{`Code WeProov : ${mission.weproov_code}`}</S.Weproov>}
          </S.FlexBetween>
          <S.Padding>
            <S.FlexBetween>
              <S.Garage>{mission.company}</S.Garage>
              <span>Véhicule : {mission.vehicle_brand} {mission.vehicle_model} <br /> Type de véhicule : {vehicleIcon}</span>
            </S.FlexBetween>
          </S.Padding>
          <S.AddressContainer>
            <S.AddressLine>
              <span></span>
              <span></span>
              <span></span>
            </S.AddressLine>
            <S.Address>
              <div>{mission.origin_address ? `${mission.origin_address}, ${mission.origin_cp} ${mission.origin_city}` : `${mission.origin_city} ${mission.origin_cp}`}</div>
              <div>{mission.destination_address ? `${mission.destination_address}, ${mission.destination_cp} ${mission.destination_city}` : `${mission.destination_city} ${mission.destination_cp}`}</div>
            </S.Address>
          </S.AddressContainer>
          <S.Padding>
            <TitleSeparator title="Informations du trajet" />
            <S.Flex>
              {!props.recovery && <S.FlexColumn>
                {props.role !== 'driver' && <S.MissionInfos>Prix garage :<br /> <span>{mission.price} HT</span></S.MissionInfos>}
                {props.role !== 'garage' && <S.MissionInfos>Prix convoyeur :<br /> <S.DriverPrice $urgent={mission.urgent} className='bold' >{mission.price_driver}</S.DriverPrice>{Boolean(mission.urgent) && <S.PriceReduced> {Math.round(parseInt(mission.price_driver) * 0.9)}€</S.PriceReduced>}</S.MissionInfos>}
              </S.FlexColumn>}
              {((props.recovery && parentAddress == destination_address) || !props.recovery) && <S.FlexColumn>
                <S.MissionInfos>Distance :<br /> <span>{formatDistanceTime({ distance: mission.distance })}</span></S.MissionInfos>
                <S.MissionInfos>Durée :<br /> <span>{formatDistanceTime({ duration: mission.duration })}</span></S.MissionInfos>
              </S.FlexColumn>}
              <S.FlexColumn>
                <S.MissionInfos>Disponibilité du véhicule :<br /> <span>{`${formatDate({ time: mission.vehicle_availability, display: 'date' })}`}</span></S.MissionInfos>
                <S.MissionInfos>Date et heure de livraison :<br /> <span>{` ${mission.date_type !== 2 ? window._DATA.date_type[mission.date_type].label : ''} ${formatDate({ time: mission.date, display: 'date' })} ${mission.time ? ` - ${mission.time.replace(':', 'h')}` : ''}`}</span></S.MissionInfos>
              </S.FlexColumn>
            </S.Flex>
            <div style={{ marginTop: 10 }}>
              <S.Flex>
                {mission.recovery_date && <S.FlexColumn>
                  <S.MissionInfos>Récupération :<br /> <span>{`${formatDate({ time: mission.recovery_date, display: 'date' })}`}</span></S.MissionInfos>
                </S.FlexColumn>}
                {mission.delivery_date && <S.FlexColumn>
                  <S.MissionInfos>Livraison :<br /> <span>{`${formatDate({ time: mission.delivery_date, display: 'date' })}`}</span></S.MissionInfos>
                </S.FlexColumn>}
                {mission.recovery_delivery_date && <S.FlexColumn>
                  <S.MissionInfos>Retour :<br /> <span>{`${formatDate({ time: mission.recovery_delivery_date, display: 'date' })}`}</span></S.MissionInfos>
                </S.FlexColumn>}
              </S.Flex>
            </div>
            {mission.status >= 4 && <div style={{ marginTop: 10 }}>
              <S.Flex>
                {mission.car_registration && <S.FlexColumn>
                  <S.MissionInfos>Immatriculation :<br /> <span>{mission?.car_registration}</span></S.MissionInfos>
                </S.FlexColumn>}
                {mission.car_grey_card && <S.FlexColumn>
                  <S.MissionInfos>Numéro de châssis :<br /> <span>{mission.car_grey_card}</span></S.MissionInfos>
                </S.FlexColumn>}
              </S.Flex>
            </div>}
            {Boolean(penalties) && <div>Une pénalité de {penalties}€ à été appliquée à la mission</div>}
          </S.Padding>
        </S.Container>
        <S.Container>
          <iframe
            title="Saint-bernard trajet"
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0 }}
            referrerPolicy="no-referrer-when-downgrade"
            src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyAJfF_PxWiAglDxqlioI71wsd0gjZyx0RY&origin=${origin_address}&destination=${destination_address}`}
            allowFullScreen
          ></iframe>
        </S.Container>
      </S.Line>
      <S.Line>
        <S.Container>
          <S.Padding>
            <TitleSeparator title="Options supplémentaires" />
            <S.Flex>
              <S.FlexColumn>
                <S.MissionInfos>Nettoyage :<br /> <span>{mission.cleaning}</span></S.MissionInfos>
                <S.MissionInfos>Assurance :<br /> <span>{mission.insurance}</span></S.MissionInfos>
              </S.FlexColumn>
              <S.FlexColumn>
                <S.MissionInfos>Véhicule électrique :<br /><span> {mission.electric}</span></S.MissionInfos>
                <S.MissionInfos>Remise à niveau du carburant :<br /><span> {mission.refueling}</span></S.MissionInfos>
              </S.FlexColumn>
              <S.FlexColumn>
                <S.MissionInfos>Mise en main simple :<br /> <span>{mission.easy_to_use}</span></S.MissionInfos>
                <S.MissionInfos>w-garage :<br /> <span>{mission.special_plate}</span></S.MissionInfos>
              </S.FlexColumn>
            </S.Flex>
            {mission.detail && <S.Detail>
              <div>Détails : </div>
              <div>{mission.detail}</div>
            </S.Detail>}
          </S.Padding>
          {getValue(mission, ['options', '2']) && <S.Padding>
            <TitleSeparator title="Options personnalisées" />
            <S.Flex>
              {getValue(mission, ['options', '2']).map((option) => {
                return <S.MissionInfos>{option.name} :<br /> <span>{mission.options_custom && mission.options_custom.split(',').includes(String(option.id)) ? "Oui" : "Non"}</span></S.MissionInfos>
              })}
            </S.Flex>
            {mission.detail && <S.Detail>
              <div>Détails : </div>
              <div>{mission.detail}</div>
            </S.Detail>}
          </S.Padding>}
        </S.Container>
        <S.Container>
          <S.Padding>
            <TitleSeparator title="Informations de contact" />
            <S.TableContainer>
              <S.Table>
                <tbody>
                  {mission.referent_email && <tr>
                    <td>Référent:</td>
                    <td>{mission.referent_firstname}</td>
                    <td>{mission.referent_phone}</td>
                  </tr>}
                  <tr>
                    <td>Contact de départ:</td>
                    <td>{`${mission.starting_contact_firstname} ${mission.starting_contact_name ? mission.starting_contact_name : ''}`}</td>
                    <td>{mission.starting_contact_phone}</td>
                    <td>{mission.starting_contact_email}</td>
                  </tr>
                  <tr>
                    <td>Contact d'arrivée:</td>
                    <td>{`${mission.incoming_contact_firstname} ${mission.incoming_contact_name ? mission.incoming_contact_name : ''}`}</td>
                    <td>{mission.incoming_contact_phone}</td>
                    <td>{mission.incoming_contact_email}</td>
                  </tr>
                  {mission.driver_name && <tr>
                    <td>Conducteur:</td>
                    <td>{`${mission.driver_firstname} ${mission.driver_name}`}</td>
                    <td>{mission.driver_phone}</td>
                    <td>{mission.driver_email}</td>
                  </tr>}

                </tbody>
              </S.Table>
            </S.TableContainer>

          </S.Padding>
        </S.Container>
      </S.Line>

    </S.Wrapper>}
  </>
};

export default MissionDetailRecap;

const S = {};

S.Wrapper = styled.div`
  background:white;
  border-radius:10px;
  box-shadow: 0 20px 27px 0 rgb(0 ,0, 0, 5%);
  overflow: hidden;
`

S.Line = styled.div`
  display: flex;
  &>div{
    flex:1
  }

  @media (max-width: 1300px) {
    flex-direction: column;
  }
`

S.Container = styled.div`
  iframe{
    min-height: 200px;
  }
`

S.Flex = styled.div`
  display: flex;
  gap:20px;
  &>div{
    flex:1
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

S.FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`

S.Padding = styled.div`
  padding:20px;
`

S.FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

S.AddressContainer = styled.div`
  position: relative;
  padding:20px 20px 20px 60px;
  background:var(--secondary-light);
  height:80px;
`

S.AddressLine = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
  gap:4px;
  position:absolute;
  left:20px;
  top:15px;
  height:calc(100% - 32px);
  span:nth-child(2){
    height:100%;
    width:2px;
    border-left: 2px dotted var(--primary-color)
  }
  span:not(:nth-child(2)){
    width:22px;
    min-height:22px;
    background:#14483C;
    border-radius: 100%;
    border:2px solid var(--primary-color);
  }
  span:nth-child(1){
    background:white;
  }
  span:nth-child(3){
    background-color: var(--primary-color);
    background-image: linear-gradient(45deg, #FFF 25%, transparent 25%, transparent 75%, #FFF 75%), linear-gradient(45deg, #FFF 25%, transparent 25%, transparent 75%, #FFF 75%);
    background-size: 8px 8px;
    background-position: -2px -2px, 50px 50px;
    border-color: var(--primary-color);
  }
`

S.Address = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height:100%;
`

S.Detail = styled.div`
  padding:10px;
  margin-top: 20px;
  background:#F5F5F5;
  display: flex;
  flex-direction: column;
  gap:10px;
  div:nth-child(1){
    color:#B3B3B3;
  }
`

S.Reference = styled.div`
  padding:10px;
  background:#2D2D2D;
  color:white;
  width:100%;
`

S.Weproov = styled.div`
  padding:10px;
  background:var(--secondary-color);
  color:white;
  width:100%;
`

S.Garage = styled.h2`
  margin:0;
`

S.MissionInfos = styled.div`
font-size: 14px;
  span{
    font-weight: bold;
    color:var(--primary-color);
    font-size: 20px;
  }
`

S.MissionContact = styled.div`
  display: flex;
  flex-direction: column;
  gap:10px;
`


S.DriverPrice = styled.span`
  position:relative;
  &:after{
    content:' ';
    display:${({ $urgent }) => $urgent ? 'block' : 'none'};
    width:100%;
    height:3px;
    background:#d03131;
    position: absolute;
    top:50%;
    left:0;
    transform: translateY(-50%) rotate(-10deg);
  }
`

S.PriceReduced = styled.span`
  margin-right: 10px;
`

S.TableContainer = styled.div`
  max-width: 100%;
  width: 100%;

  @media (max-width: 1300px) {
    overflow-x: auto;
  }
`;

S.Table = styled.table`
  border-radius: 0.5rem;
  border: 0;
  border-collapse: collapse;
  width: 100%;

  tbody {
    background: var(--white);
    color: var(--text-color);
  }

  td {
    padding: 1rem 1rem 1rem 0;
  }
`;

S.Actions = styled.td`
  display: flex;
  gap: 10px;
  align-items: center;

  & svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
    color: var(--primary-color);
  }

  & .shadow-md {
    height: 2rem
  }
`;

S.Vehicle = styled.svg`
  transform:translateY(5px)
`