/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { getValue } from '../contexts/Utils';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import FormHelperText from '@mui/material/FormHelperText';
import DeleteIcon from '@mui/icons-material/Delete';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';

//
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (opt) => {
  const field = opt.field;
  const defaultValue = getValue(field, ['default']) || field.value || '';
  const dataSet = {};

  const [value, setValue] = useState(defaultValue);

  const handleChange = (e) => {
    setValue(e.target.value);
    if (opt.field.handleChange) {
      opt.field.handleChange(e.target.value)
    }
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue);
    }
  }, [defaultValue]);

  Object.entries(field.dataSet || {}).map(([key, value]) => (dataSet[key] = value));

  let textField = (
    <>
      <TextField
        multiline={field.multiline}
        rows={field.rows ? 6 : 1}
        InputLabelProps={{ shrink: true }}
        label={field.label}
        type={field.type}
        name={field.name}
        value={value}
        onChange={handleChange}
        inputProps={{
          ...dataSet,
        }}
        error={field.error ? true : false}
        required={field.required}
        size="small"
        helperText={field.error}
        placeholder={field.placeholder}
        disabled={field.disabled}
      />
      {'help' in field && field.help}
    </>
  );
  return textField;
};

//
// ─── SWITCH ───────────────────────────────────────
//
export const SwitchField = (opt) => {
  const field = opt.field;
  const defaultValue =
    getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0;
  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    if (defaultValue !== selected) {
      setSelected(defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={'value' in field ? Boolean(field.value) : selected ? true : false}
            defaultChecked={field.defaultChecked}
            disabled={field.disabled}
            value={selected}
            onChange={() => {
              setSelected(selected === 0 ? 1 : 0);
              field.onChange && field.onChange(selected === 0 ? 1 : 0, field.name);
            }}
            name={field.name}
          />
        }
        label={<span style={{ fontSize: field.size || '14px' }}>{field.label || ''}</span>}
      />
      {
        Boolean(field.textfield && selected) && (
          <TextField
            type='text'
            name={`${field.textName}`}
            required={true}
            size="small"
            InputLabelProps={{ shrink: true }}
            label={field.textLabel}
            sx={{ marginTop: '10px' }}
            helperText={field.textError}
            error={field.textError ? true : false}
            defaultValue={field.textValue || ''}
          />
        )
      }
    </>
  );
};

//
// ─── RADIO ───────────────────────────────────────
//
export const RadioField = (opt) => {
  const field = opt.field;
  const defaultValue =
    getValue(field, ['default']) !== undefined ? getValue(field, ['default']) : field.value || 0;

  const [value, setValue] = useState(defaultValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value);
    }
  };

  return (
    <>
      <FormControl>
        <FormLabel id="demo-radio-buttons-group-label">{field.label}</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-radio-buttons-group-label"
          value={value}
          name={field.name}
          onChange={handleChange}
        >
          {field.value.map((radio) => (
            <FormControlLabel className={field.class ? field.class : ''} value={radio.value} control={<Radio />} label={radio.label} />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

// 
// ─── REGISTER CUSTOM ───────────────────────────────────────
//
export const RegisterCustom = (props) => {
  const field = props.field;
  const defaultValue = getValue(field, ['default']) || 0;
  const [value, setValue] = useState(defaultValue);

  const handleChange = (id) => {
    setValue(id);
    if (field.handleChange) field.handleChange(id);
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  return <S.UserTypeList>
    <S.UserTypeChoice
      $selected={value === 1}
      onClick={() => handleChange(1)}
      type="button"
    >
      {/* prettier-ignore */}
      <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
        <g clipPath="url(#clip0_2_168)">
          <path d="M26.25 42.0586V52.1953C16.8281 50.6133 9.38672 43.1719 7.81641 33.75H19.793L26.25 42.0586ZM33.75 42.0586L40.207 33.75H52.1836C50.6133 43.1719 43.1719 50.6133 33.75 52.1836V42.0586ZM41.25 26.25L40.4062 24.5742C39.7734 23.3086 38.4727 22.5 37.0547 22.5H22.9453C21.5273 22.5 20.2266 23.2969 19.5938 24.5742L18.75 26.25H7.81641C9.59766 15.6094 18.8555 7.5 30 7.5C41.1445 7.5 50.4023 15.6094 52.1836 26.25H41.25ZM30 60C37.9565 60 45.5871 56.8393 51.2132 51.2132C56.8393 45.5871 60 37.9565 60 30C60 22.0435 56.8393 14.4129 51.2132 8.7868C45.5871 3.16071 37.9565 0 30 0C22.0435 0 14.4129 3.16071 8.7868 8.7868C3.16071 14.4129 0 22.0435 0 30C0 37.9565 3.16071 45.5871 8.7868 51.2132C14.4129 56.8393 22.0435 60 30 60Z" fill="#14483C" />
        </g>
        <defs>
          <clipPath id="clip0_2_168">
            <rect width="60" height="60" fill="white" />
          </clipPath>
        </defs>
      </svg>
      Un convoyeur
    </S.UserTypeChoice>
    <S.UserTypeChoice
      $selected={value === 2}
      onClick={() => handleChange(2)}
      type="button"
    >
      {/* prettier-ignore */}
      <svg xmlns="http://www.w3.org/2000/svg" width="75" height="60" viewBox="0 0 75 60" fill="none">
        <g clipPath="url(#clip0_2_172)">
          <path d="M40.2891 1.03125C38.5547 0.046875 36.4336 0.046875 34.711 1.03125L2.82425 19.2422C1.03128 20.2734 0.410187 22.5586 1.42972 24.3633C2.44925 26.168 4.74612 26.7891 6.55081 25.7578L37.5 8.07422L68.4492 25.7578C70.2422 26.7891 72.5391 26.1562 73.5703 24.3633C74.6016 22.5703 73.9688 20.2734 72.1758 19.2422L40.2891 1.03125ZM29.2735 26.25H45.7266C46.5235 26.25 47.2266 26.7539 47.4961 27.4922L49.7227 33.75H25.2774L27.5157 27.4922C27.7852 26.7422 28.4883 26.25 29.2852 26.25H29.2735ZM20.4492 24.9727L17.1328 34.2656C14.7774 35.2617 13.125 37.5938 13.125 40.3125V45V52.5V56.25C13.125 58.3242 14.8008 60 16.875 60H18.75C20.8242 60 22.5 58.3242 22.5 56.25V52.5H52.5V56.25C52.5 58.3242 54.1758 60 56.25 60H58.125C60.1992 60 61.875 58.3242 61.875 56.25V52.5V45V40.3125C61.875 37.5938 60.2227 35.2617 57.8672 34.2656L54.5508 24.9727C53.2149 21.2461 49.6875 18.75 45.7266 18.75H29.2735C25.3125 18.75 21.7735 21.2461 20.4492 24.9727ZM22.5 40.3125C23.246 40.3125 23.9613 40.6088 24.4888 41.1363C25.0162 41.6637 25.3125 42.3791 25.3125 43.125C25.3125 43.8709 25.0162 44.5863 24.4888 45.1137C23.9613 45.6412 23.246 45.9375 22.5 45.9375C21.7541 45.9375 21.0387 45.6412 20.5113 45.1137C19.9838 44.5863 19.6875 43.8709 19.6875 43.125C19.6875 42.3791 19.9838 41.6637 20.5113 41.1363C21.0387 40.6088 21.7541 40.3125 22.5 40.3125ZM49.6875 43.125C49.6875 42.3791 49.9838 41.6637 50.5113 41.1363C51.0387 40.6088 51.7541 40.3125 52.5 40.3125C53.2459 40.3125 53.9613 40.6088 54.4888 41.1363C55.0162 41.6637 55.3125 42.3791 55.3125 43.125C55.3125 43.8709 55.0162 44.5863 54.4888 45.1137C53.9613 45.6412 53.2459 45.9375 52.5 45.9375C51.7541 45.9375 51.0387 45.6412 50.5113 45.1137C49.9838 44.5863 49.6875 43.8709 49.6875 43.125Z" fill="#14483C" />
        </g>
        <defs>
          <clipPath id="clip0_2_172">
            <rect width="75" height="60" fill="white" />
          </clipPath>
        </defs>
      </svg>
      Un garage
    </S.UserTypeChoice>
    <input type="hidden" name={field.name} value={value} />
  </S.UserTypeList>
}

//
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (opt) => {
  const field = opt.field;
  const defaults = getValue(field, ['default']);

  // Define the name and value of select items
  const dataLabel = field.dataLabel || 'name';
  const dataValue = field.dataValue || 'id';

  const [select, selectValue] = useState(defaults);

  const handleChange = (event) => {
    selectValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value);
    }
  };

  useEffect(() => {
    if (defaults && select !== defaults) {
      selectValue(defaults);
    }
  }, [defaults]);

  return (
    <FormControl variant="outlined" size="small">
      <InputLabel id="simple-select-label" shrink>
        {field.label}
      </InputLabel>
      <Select
        value={select}
        input={
          <OutlinedInput notched name={field.name} id="simple-select-label" label={field.label} />
        }
        onChange={handleChange}
        disabled={field.disabled}
      >
        {field.data.map((value) => (
          <MenuItem key={value[dataValue]} value={value[dataValue]}>
            {value[dataLabel]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

//
// ─── CHECKBOX ───────────────────────────────────────
//
export const CheckboxField = (opt) => {
  const field = opt.field;
  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [checked, setChecked] = useState(field.checked || false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    if (field.handleChange) {
      field.handleChange(event.target.checked);
    }
  };

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      display: 'flex',
    }
  });

  const classes = useStyle();

  return (
    <>
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            required={field.required}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'controlled' }}
            name={field.name}
          />
        }
        label={field.label}
      /> */}
      <div>
        <Checkbox checked={checked} required={field.required} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} name={field.name} />
        <label for dangerouslySetInnerHTML={{ __html: field.label }} />
      </div>
      {field.error && <FormHelperText error={true}>{field.error}</FormHelperText>}
    </>
  );
};

//
// ─── MULTIPLE ───────────────────────────────────────
//
export const Multiple = (opt) => {
  const field = opt.field;
  const fieldName = field.name;
  const titles = field.titles;
  const defaults = getValue(field, ['default']);

  const componentValue = getValue(field, ['components', 'value']);

  //
  // ─── CHAMPS NECESSAIRES ───────────────────────────────────────
  //
  let multipleFields = {};

  if (defaults) {
    Object.values(JSON.parse(defaults)).forEach((el, index) => {
      const elementId = `${field.name}_${index}`;
      multipleFields[elementId] = [
        {
          type: 'text',
          component: 'text',
          label: titles.label,
          value: el.label,
          name: `${field.name}_label_${index}`,
        },
        {
          type: 'text',
          component: componentValue,
          label: titles.value,
          value: el.value,
          name: `${field.name}_value_${index}`,
          multiline: field.multiline,
          hidden: field.hidden,
        },
      ];
    });
  } else {
    multipleFields = {
      1: [
        { type: 'text', component: 'text', label: titles.label, name: `${fieldName}_label_1` },
        {
          type: 'text',
          component: componentValue,
          label: titles.value,
          name: `${fieldName}_value_1`,
          multiline: field.multiline,
          hidden: field.hidden,
        },
      ],
    };
  }

  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [fields, setFields] = useState(multipleFields);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    multipleContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    multipleLabel: {
      display: 'flex',
      '& > span': {
        width: '50%',
      },
    },
    multipleFields: {
      display: 'flex',
      marginTop: 20,
    },
    button: {
      marginTop: '20px !important',
    },
    inputLeft: {
      marginLeft: 5,
    },
    inputRight: {
      marginRight: 5,
    },
  });

  const classes = useStyle();

  return (
    <div>
      <label>{field.label}</label>
      <div className={classes.multipleContainer + ' form__multiple'}>
        {Object.values(fields).map((element, index) => {
          return (
            <div key={index} className={classes.multipleFields}>
              {element.map((el, i) => {
                return (
                  <Formfields
                    key={i}
                    field={{
                      ...el,
                      ...{ dataSet: { 'data-attribute': 'multiple', 'data-name': fieldName } },
                    }}
                    classNameOuter={i === 0 ? classes.inputRight : classes.inputLeft}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
      {!field.removeAdd && (
        <Button
          className={classes.button}
          variant="contained"
          onClick={(event) => {
            event.preventDefault();
            let index = Object.keys(fields).length + 1;

            //
            // ─── AJOUT D'UN ELEMENT SUPPLEMENTAIRE A LA LISTE ───────────────────────────────────────
            //
            setFields(
              (fields[index] = {
                ...fields,
                [`${field.name}_${index}`]: [
                  {
                    type: 'text',
                    component: 'text',
                    label: titles.label,
                    name: `${fieldName}_label_${index}`,
                  },
                  {
                    type: 'text',
                    component: componentValue,
                    label: titles.value,
                    name: `${fieldName}_value_${index}`,
                    multiline: field.multiline,
                    hidden: field.hidden,
                  },
                ],
              })
            );
          }}
        >
          Ajouter
        </Button>
      )}
    </div>
  );
};

export const UploadFile = (opt) => {
  const field = opt.field;
  const [file, setFile] = useState();

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  let useStyle = createUseStyles({
    input: { display: 'none !important' },
    label: {
      marginBottom: 10,
    },
    error: {
      color: '#d32f2f',
      marginTop: 5,
      display: 'block',
    },
    field: {
      width: '100%',
      border: field.error ? '1px solid #d32f2f !important' : 'initial',
    },
    fileUploaded: {
      width: 'calc(50% - 10px)',
      alignItems: 'center',
    },
    fileContent: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 20,
      marginRight: 'auto',
    },
    fileIcon: {
      fontSize: '35px !important',
      color: 'var(--primary-color)',
    },
    fileLabel: {
      marginBottom: 5,
    },
    fileName: {
      fontSize: 13,
      width: 275,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  });

  const classes = useStyle();

  return (
    <>
      <div className={classes.fileUploaded} style={{ display: file ? 'flex' : 'none' }}>
        <InsertDriveFileIcon className={classes.fileIcon} />
        <div className={classes.fileContent}>
          <span className={classes.fileLabel}>{field.label}</span>
          <span className={classes.fileName}>{file}</span>
        </div>
        <IconButton
          aria-label="delete"
          onClick={() => {
            document.querySelector(`input[name='${field.name}']`).value = '';
            setFile(null);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
      <div className={classes.fileContainer} style={{ display: file ? 'none' : 'block' }}>
        <span className={classes.label}>{opt.field.label}</span>
        <label htmlFor={`${field.name}-file`} className={classes.label}>
          <Input
            className={classes.input}
            accept="image/*"
            id={`${field.name}-file`}
            type="file"
            name={field.name}
            onChange={(e) => {
              if (field.handleChange) {
                field.handleChange(e)
              } else {
                setFile(e.target.files[0].name);
              }
            }}
            required={field.required ? true : false}
          />
          <Button variant="contained" component="span" className={classes.field}>
            Télécharger
          </Button>
          {field.error && <span className={classes.error}>{field.error}</span>}
        </label>
      </div>
    </>
  );
};

//
// ─── DATE AND TIME PICKER ───────────────────────────────────────
//
const DatePicker = (props) => {
  let field = props.field;

  const [date, setDate] = useState();
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

  return (
    <TextField
      label={field.label}
      type={field.type}
      size="small"
      value={date}
      defaultValue={field.filled ? now.toISOString().slice(0, 16) : field.defaultValue ? field.defaultValue : ''}
      name={field.name}
      required={field.required}
      error={field.error ? true : false}
      helperText={field.error}
      disabled={field.disabled}
      onChange={(e) => {
        setDate(e.target.value);
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

//
// ─── BUTTON GROUP ───────────────────────────────────────
//
const GroupedButtons = (opt) => {
  const field = opt.field;
  const [counter, setCounter] = useState(1);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    label: {
      marginRight: 10,
    },
  });

  const classes = useStyle();

  //
  // ─── HANDLE INPUT INCREMENTATION OR DECREMENT ───────────────────────────────────────
  //
  const handleIncrement = () => {
    setCounter(counter + 1);
  };

  const handleDecrement = () => {
    setCounter(counter - 1);
  };
  return (
    <div>
      <span className={classes.label}>{field.label}</span>
      <ButtonGroup size="small" aria-label="small outlined button group">
        <Button disabled={counter === 1} onClick={handleDecrement}>
          -
        </Button>
        <TextField
          size="small"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          name={field.name}
          value={counter}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value <= 8) {
              setCounter(parseInt(e.target.value));
            }
          }}
          style={{ width: 40 }}
        />
        <Button disabled={counter === 8} onClick={handleIncrement}>
          +
        </Button>
      </ButtonGroup>
    </div>
  );
};
//
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const SearchField = (props) => {
  const field = props.field;
  const data = field.data;

  const [value, setValue] = useState(field.multiple ? [] : field.default || '');

  useEffect(() => {
    if (field.default !== value) {
      setValue(field.default || '');
    }
  }, [field.default]);

  return (
    <>
      <Autocomplete
        id={props.name}
        size="small"
        freeSolo
        multiple={field.multiple}
        options={data}
        noOptionsText={'Aucun résultat'}
        getOptionLabel={(option) => option.label || ''}
        onInputChange={(event, newInputValue) => {
          props.field.handleSearch({
            value: newInputValue,
            name: field.name,
            ...(props.handleSearchParams || {}),
          });
        }}
        onChange={(e, inputValue) => {
          setValue(inputValue);
          if (props.field.handleChange) {
            props.field.handleChange({ name: field.name, value: inputValue });
          }
        }}
        disabled={field.disabled}
        value={value}
        className={props.className}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            label={field.label}
            value={field.default}
            placeholder={field.placeholder}
          />
        )}
      />
      {value && <input type="hidden" name={field.name} value={value.id} />}
    </>
  );
};

//
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const Formfields = (props) => {
  let fields;

  switch (props.field.component) {
    case 'switch':
      fields = SwitchField(props);
      break;
    case 'radio':
      fields = RadioField(props);
      break;
    case 'select':
      fields = SelectField(props);
      break;
    case 'checkbox':
      fields = CheckboxField(props);
      break;
    case 'multiple':
      fields = Multiple(props);
      break;
    case 'file':
      fields = UploadFile(props);
      break;
    case 'datepicker':
      fields = DatePicker(props);
      break;
    case 'grouped_btn':
      fields = GroupedButtons(props);
      break;
    case 'search':
      fields = SearchField(props);
      break;
    case 'register_custom':
      fields = RegisterCustom(props);
      break;
    default:
      fields = Text(props);
      break;
  }

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputContainer: {
      display: props.field.hidden ? 'none' : 'flex',
      flexDirection: 'column',
      '@media screen and (max-width:780px)': {
        width: '100% !important',
      },
    },
  });

  const classes = useStyle();

  return (
    <div
      className={classes.inputContainer + ' formfield_container ' + props.classNameOuter}
      style={{ width: props.field.size || '100%' }}
    >
      {fields}
    </div>
  );
};

export default Formfields;

const S = {};

// S.Radio = styled.div`
//   background: ${({ $selected }) => $selected ? "hsl(var(--secondary) / 0.25)" : "transparent"};
//   padding: 1rem;
//   display: flex;
//   flex-direction: column;
//   gap: 1rem;
//   border: 2px solid ${({ $selected }) => ($selected ? "var(--primary-color)" : "transparent")};
//   border-radius: 0.25rem;

//   justify-content: center;
//   align-items: center;
//   cursor: pointer;

//   transition: all 0.2s ease-in-out;

//   &:hover {
//     background: hsl(var(--secondary) / 0.25);
//   }
// `

S.UserTypeList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

S.UserTypeChoice = styled.button`
  background: ${({ $selected }) => $selected ? "rgba(225, 243, 233, 0.25)" : "transparent"};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  border: 2px solid ${({ $selected }) => ($selected ? "var(--primary-color)" : "transparent")};
  border-radius: 0.25rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: all 0.2s ease-in-out;

  &:hover {
    background: rgba(225, 243, 233, 0.25);
  }
`;
